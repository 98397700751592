<mat-card class="card-outer">
    <div class="flex column top-bar-container">
        <div style="flex-flow: row wrap;" class="top-bar  mat-elevation-z8">
            <mat-form-field appearance="outline" class="list" style="width: 30em;">
                <mat-label>Organization</mat-label>
                <mat-select [(value)]="selected_org_obj" (selectionChange)="changeOrg()">
                    <mat-select-trigger>
                        {{selected_org_obj.name}}
                    </mat-select-trigger>
                    <mat-option *ngFor="let org of orgs" [value]="org">
                        <span style="margin-right: 1em; background-color: lightgray; border-radius: 14px; padding: 5px; font-size: smaller;">{{org.role}}</span>
                        <span>{{org.name}}</span>
                    </mat-option>
                </mat-select>
            </mat-form-field>

        </div>
        <div style="width: 100%; height: 4px; background-color: rgb(111 157 43);" *ngIf="topBarLoading == false">
        </div>
        <mat-progress-bar mode="indeterminate" color="accent" *ngIf="topBarLoading == true"></mat-progress-bar>
    </div>
    <div>
        <mat-card *ngIf="!org_id"  style="place-content: stretch center;align-items: center;padding: 16px;">
            <span style="color:#777">
                Please Select an Organization
            </span>
        </mat-card>
        <mat-card *ngIf="noSiteToDisplay" class="flex column" style="justify-content: center; max-width: 100%; padding: 2em;">
            <div style="color:#777; margin: 1em auto; justify-content: center; max-width: 100%;">
                You don't have access to any Site in this Organization. Please chose another Organization.
            </div>
            <div style="color:#777; margin: 1em auto; justify-content: center; max-width: 100%;">
                Only the Sites with "Administrator" authorization are listed.
            </div>
        </mat-card>
    </div>
    <div class="flex row container-sites">
        <mat-card *ngFor="let site of sites" class="mat-elevation-z2 flex column card-site" >
            <div (click)="setSite(site)">
                <mat-card-title style="margin:10px">
                    {{site.name}}
                </mat-card-title>
                <mat-card-subtitle style="margin: 10px">
                    <div style="width: 100%;">{{site.address}}</div>
                </mat-card-subtitle>
            </div>
            <div (click)="setSite(site)">
                <mat-card-content style="margin: 0; padding: 0;">
                    <google-map [center]="site.latlng" [zoom]="map.zoom" height="300px" width="300px" [options]="map.options">
                        <map-marker [position]="site.latlng"></map-marker>
                        <map-info-window>Test</map-info-window>
                    </google-map>
                </mat-card-content>
            </div>
        </mat-card>
    </div>

</mat-card>