<style>
    /* Chrome, Safari, Edge, Opera */
    
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
    /* Firefox */
    
    .icon-text {
        display: flex;
        align-items: center;
        cursor: default;
    }
    
    input[type=number] {
        -moz-appearance: textfield;
    }
    
    .delete-icon {
        color: #333;
        transition: color 250ms ease-in-out;
    }
    
    .delete-icon:hover {
        color: red;
    }
    
    .add-icon {
        color: #333;
        transition: color 250ms ease-in-out;
    }
    
    .add-icon:hover {
        color: green;
    }
    
    .claim-code-input {
        background-color: white;
        transition: color 250ms ease-in-out;
    }
    
    .claim-code {
        color: black;
        transition: color 250ms ease-in-out;
    }
    
    .success {
        color: green;
    }
    
    .warning {
        color: coral;
    }
    
    .error {
        color: red;
    }
</style>
<h1 mat-dialog-title>Claim new devices</h1>
<div mat-dialog-content class="flex column">
    <div style="margin: 0 1em 1em; color: gray; font-size: small">HINT: you can paste mutliple claim codes separated by a SPACE, a COMMA or a SEMICOLUMN</div>
    <div *ngIf="!isDone" class="flex row" style="padding: 0 2em">
        <mat-form-field>
            <mat-label>Claim Code</mat-label>
            <input matInput placeholder="ABCDE-12345-F6G7H" (keydown.enter)="add()" [(ngModel)]="inputClaimCodes" cdkFocusInitial>
        </mat-form-field>
        <button mat-icon-button (click)="add()" style="height: 100%;" class="add-icon">
        <mat-icon matTooltip="Add Claim Codes" aria-label="Add Claim Codes">done</mat-icon>
    </button>
    </div>
    <div style="max-width: 80em; min-width: 20em;">
        <div *ngIf="claimCodes.length > 0" fxFlex="1 1 0" style="width: 100%; flex-flow: row wrap;">
            <mat-card class="flex row" *ngFor="let claimCode of claimCodes" style="background-color: #eeeeee; margin:5px; border-radius: 1.7em; padding: 0; height: 4em;">
                <mat-form-field class="claim-code" style="margin: 0 1em; width: 11em;" [ngClass]="{error: !claimCode.valid, warning: claimCode.duplicated}">
                    <input matInput [(ngModel)]="claimCode.code" [readonly]="claimCode.readonly">
                </mat-form-field>
                <div style="padding-bottom: 0.5em; margin-right: 1em;" class="flex row">
                    <div *ngIf="claimCode.processed && claimCode.success" style="height: 100%;" class="success icon-text">
                        <mat-icon matTooltip="Claim Code Added" aria-label="Claim Code Added">check_circle</mat-icon>
                    </div>
                    <div *ngIf="claimCode.processed && !claimCode.success && claimCode.reason" style="height: 100%;" class="error icon-text">
                        <mat-icon [matTooltip]="claimCode.reason" [aria-label]="claimCode.reason">warning</mat-icon>
                    </div>
                    <div *ngIf="!claimCode.processed && !claimCode.valid" style="height: 100%;" class="error icon-text">
                        <mat-icon matTooltip="Invalid Format" aria-label="Invalid Format">warning</mat-icon>
                    </div>
                    <div *ngIf="!claimCode.processed && claimCode.duplicated" style="height: 100%;" class="warning icon-text">
                        <mat-icon matTooltip="Duplicated Claim Code" aria-label="Duplicated Claim Code">content_copy</mat-icon>
                    </div>
                    <button *ngIf="!claimCode.processed" mat-icon-button (click)="remove(claimCode)" style="height: 100%;" class="delete-icon">
                        <mat-icon matTooltip="Delete Claim Code" aria-label="Delete Claim Code">delete</mat-icon>
                    </button>
                    <button *ngIf="!claimCode.processed" mat-icon-button (click)="edit(claimCode)" style="height: 100%;" class="add-icon">
                        <mat-icon matTooltip="Edit Claim Code" aria-label="Edit Claim Code">edit</mat-icon>
                    </button>
                </div>
            </mat-card>
        </div>
    </div>
</div>

<div mat-dialog-actions style="justify-content: space-around;" class="flex row">
    <button *ngIf="!isDone" mat-button (click)="cancel()">Cancel</button>
    <button *ngIf="!isDone" mat-raised-button color="accent" cdkFocusInitial (click)="confirm()" [disabled]="claimButtonDisabled">Claim</button>
    <button *ngIf="isDone" mat-button (click)="reset()" [disabled]="isWorking">Reset</button>
    <button *ngIf="isDone" mat-raised-button color="accent" cdkFocusInitial (click)="details()" [disabled]="isWorking">View Details</button>
    <button *ngIf="isDone" mat-raised-button color="accent" cdkFocusInitial (click)="cancel()" [disabled]="isWorking">Done</button>
</div>