<mat-card class="card-outer">

    <form [formGroup]="frmStepLogin" class="login-form">
        <div class="login-form-outer flex column" [formGroup]="frmStepLogin" >
            <div class="login-form-inner">
                <div class="login-step flex column">
                    <mat-form-field appearance="outline" style="width: 100%">
                        <mat-label>Mist Cloud</mat-label>
                        <mat-select formControlName="host">
                            <mat-option *ngFor="let host of hosts " [value]="host.value">
                                {{host.viewValue}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="login-form-or flex row">
                    <span></span>
                    <button mat-fab color="accent" aria-label="AND" class="disable-click">
                        AND
                    </button>
                    <span></span>
                </div>

                <div class="login-step flex row">

                    <div formGroupName="credentials" class="login-form-inputs flex column">
                        <h3>Access with Login/Password</h3>
                        <mat-form-field appearance="outline" style="margin-top: 2em;">
                            <mat-label>Email</mat-label>
                            <input matInput formControlName="email" type="email">
                        </mat-form-field>

                        <mat-form-field appearance="outline">
                            <mat-label>Password</mat-label>
                            <input matInput type="password" formControlName="password" (keydown.enter)="submitCredentials()" />
                        </mat-form-field>
                        <div style="margin-bottom: 1em; min-height: 20px;">
                            <span style="color:red; font-weight: normal; ">{{error_mess.credentials}}</span>
                        </div>
                        <div></div>
                        <button mat-raised-button color="primary" (click)="submitCredentials()">Continue with
                            credentials</button>
                    </div>
                    <div class="login-form-or flex column">
                        <mat-divider [vertical]="true"></mat-divider>
                        <button mat-fab color="accent" aria-label="OR" class="disable-click" style="margin-bottom: 3.5em;">
                            OR
                        </button>
                        <mat-divider [vertical]="true"></mat-divider>
                    </div>
                    <div class="login-form-inputs flex column">
                        <h3>Access with API Token</h3>
                        <mat-form-field appearance="outline" style="margin-top: 3em">
                            <mat-label>API Token</mat-label>
                            <input type="text" matInput formControlName="token" (keydown.enter)="submitToken()" />
                        </mat-form-field>
                        <div>
                            <span style="color:red; font-weight: normal;">{{error_mess.token}}</span>
                        </div>
                        <div></div>
                        <button mat-raised-button color="primary" (click)="submitToken()">Continue with API
                            Token</button>
                    </div>
                </div>
                <div class="loading flex row" *ngIf="loading || hosts_loading">
                    <div class="flex column">
                        <mat-spinner strokeWidth=3></mat-spinner>
                    </div>
                </div>
            </div>
        </div>
    </form>
</mat-card>
<div class="disclaimer">
    <div>
        {{disclaimer}}
    </div>
</div>
<div class="source flex row">
    <a *ngIf="github_url" href={{github_url}} target="_blank"><img src="static/github.png"></a>
    <a *ngIf="docker_url" href={{docker_url}} target="_blank"><img src="static/docker.png"></a>
</div>