<div style="flex-direction: row;box-sizing: border-box;display: flex;visibility: visible!important;">
<mat-drawer-container class="sidenav-container">
    <mat-drawer mode="side" opened class="sidenav">
    <div style="display: flex;flex-direction: column;justify-content: space-between;height: 100%;">
        <div  class="flex column psk-table-container">
            <table mat-table [dataSource]="filteredDevicesDatabase" style="background: none; margin-bottom: 1em;">
                <ng-container matColumnDef="device">
                    <th mat-header-cell *matHeaderCellDef class="flex column" style="padding-right: 0; padding-left: 0;place-content: stretch space-between;align-items: stretch;">
                        <div class="flex row filter-nav">
                            <mat-form-field style="width: 90%;" color="accent">
                                <mat-label>Filter</mat-label>
                                <input matInput (keyup)="applyFilter($event)" placeholder="Ex. switch name" #input>
                            </mat-form-field>
                            <button mat-button (click)="getDevices()" [disabled]="topbar_loading" style="height: 100%; margin: auto ">
                                <mat-icon matTooltip="Refresh list" aria-label="Refresh list">refresh</mat-icon>
                            </button>
                        </div>
                        <mat-progress-bar mode="indeterminate" color="accent" *ngIf="topbar_loading == true">
                        </mat-progress-bar>
                        <div style="width: 100%; height: 4px; background-color: rgb(111 157 43);" *ngIf="topbar_loading == false">
                        </div>
                    </th>
                    <td mat-cell *matCellDef="let element" [ngClass]="{selected: editingDevice && element.mac === editingDevice.mac}">
                        <a role="button" (click)="editDevice(element)" class="flex row device" style="cursor: pointer;place-content: stretch flex-start;align-items: stretch;">
                            <div class="flex row" style="place-content: center flex-start;align-items: stretch;">
                                <mat-icon *ngIf="element.members.length <= 1 " aria-hidden="false" [ngStyle]="{'color':element.status === 'connected' ? '#6f9d2b' : 'lightgray' }">
                                    check_box_outline_blank
                                </mat-icon>
                                <mat-icon *ngIf="element.members.length > 1" aria-hidden="false" [ngStyle]="{'color':element.status === 'connected' ? '#6f9d2b' : 'lightgray' }">
                                    filter_none</mat-icon>
                            </div>
                            <div class="flex column" style="margin-left: 2em;place-content: center flex-start;align-items: stretch;" fxFlex>
                                <div *ngIf="element.name">{{element.name}}
                                </div>
                                <div *ngIf="!element.name">{{element.mac}}
                                </div>
                                <div style="color: gray; font-weight: lighter;">MAC: {{element.mac}}</div>
                                <div style="color: gray; font-weight: lighter;">Model: {{element.model}}</div>
                            </div>
                        </a>
                    </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
            <mat-paginator [pageSizeOptions]="[10, 25, 50, 100]" style="background:  none;"></mat-paginator>
        </div>
    <div class="flex row" style="margin: 1em;place-content: center flex-start;align-items: stretch;">
        <button mat-raised-button color="accent" (click)="back()">Back</button>
    </div>
    </div>
</mat-drawer>
</mat-drawer-container>


<mat-card class="flex column mat-elevation-z24 max90vh" style="margin:0 1em; padding: 0;place-content: stretch center;align-items: stretch; flex:auto;">
    <div *ngIf="!editingDevice && !device_loading" style="text-align: center;font-size: large;color: gray;">
        Please select a device...
    </div>

    <div *ngIf="device_loading" class="flex row" style="justify-content: center;align-items: center;">
        <mat-spinner color="accent" strokeWidth=3></mat-spinner>
    </div>

    <div *ngIf="editingDevice && !device_loading" fxFlex class="flex row" style="height: 100%;">
        <div matColumnDef="ports" class="flex column">
            <mat-form-field class="filter" color="accent">
                <mat-label>Filter</mat-label>
                <input matInput (keyup)="applyPortFilter($event)" placeholder="Ex. switch name" #input>
            </mat-form-field>
            <div style="overflow: auto; height: 100%">
                <div *ngFor="let switchport of availablePorts">
                <div *ngIf="!readonlyPorts.includes(switchport)" [ngClass]="{'selected': canbeChecked(switchport)}" class="selectable">
                    <a role="button" class="device" class="flex row" style="cursor: pointer;place-content: stretch space-between;align-items: stretch;">
                        <div class="flex row" style="font-weight: lighter;padding: 0.5em;border-bottom: solid 1px lightgray;width: 100%;">
                            <mat-checkbox [checked]="canbeChecked(switchport)" (change)="selectPort(switchport)" class="expand-checkbox" [disabled]="readonlyPorts.includes(switchport)">
                                {{switchport}}
                                <div *ngIf="lacpPorts.hasOwnProperty(switchport)" style="margin-left: 1em; color: gray"> (LACP)</div>
                            </mat-checkbox>
                        </div>
                    </a>
                </div>
                </div>
        </div>
        </div>


        <div fxFlex style="padding:1em; overflow: auto; width: 100%;" class="flex column no-cursor">
            <!-- SWITCH VIEW -->
            <div mat-dialog-content class="flex column" style="place-content: center space-around;align-items: center;">
                <div *ngFor='let member of editingDeviceSettings.members; let index = index' style="margin-bottom: 0em;">
                    <div class="flex column switch" style="place-content: flex-start;align-items: flex-start;">
                        <div style="font-weight: lighter; width: 100%; justify-content: space-between;" class="flex row">
                            <div class="flex row">
                                <div class="hardware-model">
                                    <div>JUNIPER</div>
                                    <div style="font-weight: 500;">{{member.model}}</div>
                                </div>
                                <div style="margin-left: 5em;">ID {{member.fpc_idx}}</div>
                            </div>
                            <div class="flex row">
                                <div class="flex row">
                                    <div>CPU</div>
                                    <div *ngIf="member.cpu < 60">
                                        <mat-icon matTooltip="{{member.cpu}}%" aria-hidden="false" style="color: #6f9d2b; margin-right: 1em;" inline="true">
                                            check_circle</mat-icon>
                                    </div>
                                    <div *ngIf="member.cpu >= 60 && member.cpu < 80">
                                        <mat-icon matTooltip="{{member.cpu}}%" aria-hidden="false" style="color: #f3b108; margin-right: 1em;" inline="true">
                                            error</mat-icon>
                                    </div>
                                    <div *ngIf="member.cpu >= 80">
                                        <mat-icon matTooltip="{{member.cpu}}%" aria-hidden="false" style="color: #c92614; margin-right: 1em;" inline="true">
                                            cancel</mat-icon>
                                    </div>
                                </div>
                                <div class="flex row">
                                    <div>MEMORY</div>
                                    <div *ngIf="member.memory < 60">
                                        <mat-icon matTooltip="{{member.memory}}%" aria-hidden="false" style="color: #6f9d2b; margin-right: 1em;" inline="true">
                                            check_circle</mat-icon>
                                    </div>
                                    <div *ngIf="member.memory >= 60 && member.memory < 80">
                                        <mat-icon matTooltip="{{member.memory}}%" aria-hidden="false" style="color: #f3b108; margin-right: 1em;" inline="true">
                                            error</mat-icon>
                                    </div>
                                    <div *ngIf="member.memory >= 80">
                                        <mat-icon matTooltip="{{member.memory}}%" aria-hidden="false" style="color: #c92614; margin-right: 1em;" inline="true">
                                            cancel</mat-icon>
                                    </div>
                                </div>
                                <div *ngIf="member.poe.max_power" class="flex row">
                                    <div>POE</div>
                                    <div *ngIf="member.poe.power_draw / member.poe.max_power < 50">
                                        <mat-icon matTooltip="{{member.poe.power_draw}} W / {{member.poe.max_power}} W" aria-hidden="false" style="color: #6f9d2b; margin-right: 1em;" inline="true">
                                            check_circle</mat-icon>
                                    </div>
                                    <div *ngIf="member.poe.power_draw / member.poe.max_power >= 50 && member.poe.power_draw / member.poe.max_power < 75">
                                        <mat-icon [matTooltip]="member.poe.power_draw / member.poe.max_power" aria-hidden="false" style="color: #f3b108; margin-right: 1em;" inline="true">
                                            error</mat-icon>
                                    </div>
                                    <div *ngIf="member.poe.power_draw / member.poe.max_power >= 80">
                                        <mat-icon [matTooltip]="member.poe.power_draw / member.poe.max_power" aria-hidden="false" style="color: #c92614; margin-right: 1em;" inline="true">
                                            cancel</mat-icon>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="flex row" style="place-content: stretch flex-start;align-items: stretch;">
                            <div class="ports-container">

                                <div *ngIf="display_pic(member, 0)" class="flex row ports-text" style="place-content: center;align-items: center;flex: 1 1 100%;max-height: 100%;">
                                    <span style="width: 60px;height: 20px;text-align: center;">PIC0</span>
                                </div>
                                <div *ngFor='let port of member.ports; let index = index' style="z-index: 10;">
                                    <div *ngIf='port | includes: "/0/"' class="port" [ngClass]="{'top': index % 2 == 0, 'bottom': index % 2 == 1, 'selected': editingPortNames.includes(port)}">
                                        <div class="port-id" >{{port | split :"/":2}}</div>
                                        <div [matTooltip]="port_tooltip(port)" class="normal-port" (click)="selectPort(port)" [ngClass]="{'port-up': display_status(port), 'disabled': readonlyPorts.includes(port)}"></div>
                                    </div>
                                </div>

                                <div *ngIf="display_pic(member, 1)" class="flex row ports-text"  style="place-content: center;align-items: center;flex: 1 1 100%;max-height: 100%;">
                                    <span style="width: 60px;height: 20px;text-align: center;">PIC1</span>
                                </div>
                                <div *ngFor='let port of member.ports; let index = index' style="z-index: 10;">
                                    <div *ngIf='port | includes: "/1/"' class="port" [ngClass]="{'top': index % 2 == 0, 'bottom': index % 2 == 1, 'selected': editingPortNames.includes(port)}">
                                        <div class="port-id" >{{port | split :"/":2}}</div>
                                        <div [matTooltip]="port_tooltip(port)" class="normal-port" (click)="selectPort(port)" [ngClass]="{'port-up': display_status(port), 'disabled': readonlyPorts.includes(port)}"></div>
                                    </div>
                                </div>

                                <div *ngIf="display_pic(member, 2)" class="flex row ports-text"  style="place-content: center;align-items: center;flex: 1 1 100%;max-height: 100%;">
                                    <span style="width: 60px;height: 20px;text-align: center;">PIC2</span>
                                </div>
                                <div *ngFor='let port of member.ports; let index = index' style="z-index: 10;">
                                    <div *ngIf='port | includes: "/2/"' class="port" [ngClass]="{'top': index % 2 == 0, 'bottom': index % 2 == 1, 'selected': editingPortNames.includes(port)}">
                                        <div class="port-id" >{{port | split :"/":2}}</div>
                                        <div [matTooltip]="port_tooltip(port)" class="normal-port" (click)="selectPort(port)" [ngClass]="{'port-up': display_status(port), 'disabled': readonlyPorts.includes(port)}"></div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <!-- INFO VIEW -->
            <div mat-dialog-content class="flex column" style="place-content: stretch flex-start;align-items: stretch;">
                <div *ngIf="selectedPorts.length == 0" style="margin: 1em;place-content: stretch space-between;align-items: stretch;" class="flex column">
                    <div class="flex row">
                        <mat-card style="margin: 1em;">
                            <mat-card-content>
                                <mat-card-title class="info-title">Switch Info</mat-card-title>
                                <table class="member-details">
                                    <tr>
                                        <th>Name</th>
                                        <td>{{editingDeviceSettings.info.name}}</td>
                                    </tr>
                                    <tr>
                                        <th>Status</th>
                                        <td>
                                            <div *ngIf="editingDeviceSettings.info.status == 'connected'" style="color: #6f9d2c">{{editingDeviceSettings.info.status}}</div>
                                            <div *ngIf="editingDeviceSettings.info.status != 'connected'" style="color: #f44336">{{editingDeviceSettings.info.status}}</div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>Config Status</th>
                                        <td>{{editingDeviceSettings.info.config_status}}</td>
                                    </tr>
                                    <tr>
                                        <th>Uptime</th>
                                        <td>{{editingDeviceSettings.info.uptime | uptime}}</td>
                                    </tr>
                                    <tr>
                                        <th>Last Seen</th>
                                        <td>{{editingDeviceSettings.info.last_seen * 1000 |date:'short'}}</td>
                                    </tr>
                                </table>
                            </mat-card-content>
                        </mat-card>
                        <mat-card style="margin: 1em; flex: auto;">
                            <mat-card-content>
                                <mat-card-title class="info-title">Switch IP</mat-card-title>
                                <table class="member-details">
                                    <tr>
                                        <th></th>
                                        <th>VLAN</th>
                                        <th>type</th>
                                        <th>IP Address</th>
                                        <th>Netmask</th>
                                        <th>Gateway</th>
                                    </tr>
                                    <tr>
                                        <th>In Band</th>
                                        <td>{{editingDeviceSettings.device.ip_config.network}}</td>
                                        <td>{{editingDeviceSettings.device.ip_config.type}}</td>
                                        <td>{{editingDeviceSettings.device.ip_config.ip}}</td>
                                        <td>{{editingDeviceSettings.device.ip_config.netmask}}</td>
                                        <td>{{editingDeviceSettings.device.ip_config.gateway}}</td>
                                    </tr>
                                    <tr>
                                        <th>Out Band</th>
                                        <td>{{editingDeviceSettings.device.oob_ip_config.network}}</td>
                                        <td>{{editingDeviceSettings.device.oob_ip_config.type}}</td>
                                        <td>{{editingDeviceSettings.device.oob_ip_config.ip}}</td>
                                        <td>{{editingDeviceSettings.device.oob_ip_config.netmask}}</td>
                                        <td>{{editingDeviceSettings.device.oob_ip_config.gateway}}</td>
                                    </tr>
                                </table>
                            </mat-card-content>
                        </mat-card>
                    </div>
                    
                    
                    <mat-card style="margin: 1em;">
                        <mat-card-content>
                            <mat-card-title class="info-title">Switch Details</mat-card-title>
                            <table class="member-details">
                                <tr>
                                    <th>MODEL</th>
                                    <th>SERIAL</th>
                                    <th>MAC ADDRESS</th>
                                    <th>VERSION</th>
                                    <th>VC MEMBER #</th>
                                    <th>VC MEMBER ROLE</th>
                                    <th>POE</th>
                                    <th>POE BUDGET</th>
                                </tr>
                                <tr *ngFor="let member of editingDeviceSettings.members; let index = index">
                                    <td>{{member.model}}</td>
                                    <td>{{member.serial}}</td>
                                    <td>{{member.mac}}</td>
                                    <td>{{member.version}}</td>
                                    <td>{{member.fpc_idx}}</td>
                                    <td>{{member.vc_role}}</td>
                                    <td><div *ngIf="member.poe">{{member.poe.power_draw}} W</div></td>
                                    <td><div *ngIf="member.poe">{{member.poe.max_power}} W</div></td>
                                </tr>
                            </table>
                        </mat-card-content>
                    </mat-card>
                </div>
            </div>
            <!-- CONFIG VIEW -->
            <div *ngIf="selectedPorts.length >= 1">
                <!-- PORT LIST -->
                <mat-card style="margin: 1em;">
                    <mat-card-content>
                        <div style="max-height: 23em; overflow: auto;">

                            <table class="port-list-details">
                                <tr>
                                    <th>Port</th>
                                    <th>Status</th>
                                    <th>Type</th>
                                    <th>LLDP Neighbor System Name</th>
                                    <th>LLDP Neighbor MAC</th>
                                    <th>LLDP Neighbor Port Description</th>
                                </tr>
                                <tr *ngFor='let ae_uuid of selectedLacps'>
                                    <td class="flex column"> 
                                        <div *ngFor="let port of selectedPortsStats">
                                            <div *ngIf="lacpPorts.hasOwnProperty(port.port_id) && lacpPorts[port.port_id] == ae_uuid">
                                                {{port.port_id}} (LACP)
                                            </div>
                                        </div>
                                    </td>
                                    <td> 
                                        <div *ngFor="let port of selectedPortsStats">
                                            <div *ngIf="lacpPorts.hasOwnProperty(port.port_id) && lacpPorts[port.port_id] == ae_uuid"  style="height: 2em;">
                                                <div *ngIf="port.up">
                                                    <mat-icon matTooltip="UP" aria-hidden="false" style="color: #6f9d2b; margin-right: 1em;font-size: large;line-height: normal;" inline="true">arrow_circle_up</mat-icon>
                                                </div> 
                                                <div *ngIf="!port.up">
                                                    <mat-icon matTooltip="DOWN" aria-hidden="false" style="color: gray; margin-right: 1em;font-size: large;line-height: normal;" inline="true">arrow_circle_down</mat-icon>
                                                </div> 
                                            </div>
                                        </div>
                                    </td>
                                    <td> 
                                        <div *ngFor="let port of selectedPortsStats">
                                            <div *ngIf="lacpPorts.hasOwnProperty(port.port_id) && lacpPorts[port.port_id] == ae_uuid">
                                                {{port.media_type}}
                                            </div>
                                        </div>
                                    </td>
                                    <td> 
                                        <div *ngFor="let port of selectedPortsStats">
                                            <div *ngIf="lacpPorts.hasOwnProperty(port.port_id) && lacpPorts[port.port_id] == ae_uuid">
                                                {{port.neighbor_system_name}}
                                            </div>
                                        </div>
                                    </td>
                                    <td> 
                                        <div *ngFor="let port of selectedPortsStats">
                                            <div *ngIf="lacpPorts.hasOwnProperty(port.port_id) && lacpPorts[port.port_id] == ae_uuid">
                                                {{port.neighbor_mac}}
                                            </div>
                                        </div>
                                    </td>
                                    <td> 
                                        <div *ngFor="let port of selectedPortsStats">
                                            <div *ngIf="lacpPorts.hasOwnProperty(port.port_id) && lacpPorts[port.port_id] == ae_uuid">
                                                {{port.neighbor_port_desc}}
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                                <tr *ngFor='let port of selectedPortsStats'>
                                    <td *ngIf="!lacpPorts.hasOwnProperty(port.port_id)"> 
                                        <div>{{port.port_id}}</div>
                                    </td>
                                    <td *ngIf="!lacpPorts.hasOwnProperty(port.port_id)"> 
                                        <div *ngIf="port.up">
                                            <mat-icon matTooltip="UP" aria-hidden="false" style="color: #6f9d2b; margin-right: 1em;font-size: large;line-height: normal;" inline="true">arrow_circle_up</mat-icon>
                                        </div> 
                                        <div *ngIf="!port.up">
                                            <mat-icon matTooltip="DOWN" aria-hidden="false" style="color: gray; margin-right: 1em;font-size: large;line-height: normal;" inline="true">arrow_circle_down</mat-icon>
                                        </div> 
                                    </td>
                                    <td *ngIf="!lacpPorts.hasOwnProperty(port.port_id)"> 
                                        <div>{{port.media_type}}</div>
                                    </td>
                                    <td *ngIf="!lacpPorts.hasOwnProperty(port.port_id)"> 
                                        <div>{{port.neighbor_system_name}}</div>
                                    </td>
                                    <td *ngIf="!lacpPorts.hasOwnProperty(port.port_id)"> 
                                        <div>{{port.neighbor_mac}}</div>
                                    </td>
                                    <td *ngIf="!lacpPorts.hasOwnProperty(port.port_id)"> 
                                        <div>{{port.neighbor_port_desc}}</div>
                                    </td>
                                </tr> 
                            </table>
                        </div>
                    </mat-card-content>
                </mat-card>
                <!-- PORT CONFIG-->
                <mat-card style="margin: 1em;">
                    <mat-card-content>
                        <mat-card-title class="info-title">Selected Ports Configuration</mat-card-title>
                            <form [formGroup]="frmPort" class="flex column create-form">
                                <!-- PORT -->
                                <div class="flex row config-container">
                                    <div class="config-subtitle">
                                        <mat-card-subtitle>PORT</mat-card-subtitle>
                                    </div>
                                    <div class="flex row">
                                        <div class="flex column mist-space">
                                            <mat-label>Port Status</mat-label>
                                            <mat-slide-toggle formControlName="enabled">
                                                <span *ngIf="frmPort.value.enabled == true">Enabled </span>
                                                <span *ngIf="frmPort.value.enabled != true">Disabled </span>
                                            </mat-slide-toggle>
                                        </div>
                                        <div class="flex column mist-space">
                                            <mat-label>PoE</mat-label>
                                            <mat-slide-toggle formControlName="poe">
                                                <span *ngIf="frmPort.value.poe == true">Enabled </span>
                                                <span *ngIf="frmPort.value.poe != true">Disabled </span>
                                            </mat-slide-toggle>
                                        </div>
                                    </div>
                                </div>
                                <!-- VLAN -->
                                <div class="flex row config-container">
                                    <div class="config-subtitle">
                                        <mat-card-subtitle>VLAN</mat-card-subtitle>
                                    </div>
                                    <!-- VLAN MODE -->
                                    <div class="flex column" style="align-items: flex-start; flex-grow: 1;">
                                        <mat-form-field appearance="outline" class="mist-space">
                                            <mat-label>VLAN Mode</mat-label>
                                            <mat-select formControlName="mode">
                                                <mat-option value="access">
                                                    Access
                                                </mat-option>
                                                <mat-option value="trunk">
                                                    Trunk
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                        <!-- ACCESS -->
                                        <div *ngIf="frmPort.value.mode == 'access'">
                                            <mat-form-field appearance="outline" class="mist-space">
                                                <mat-label>VLAN</mat-label>
                                                <mat-select formControlName="port_network">
                                                    <mat-option value="default">default (1)</mat-option>
                                                    <mat-option *ngFor="let vlan of editingDeviceSettings.networks | mapToArray" [value]="vlan.key">
                                                        {{vlan.key}} ({{vlan.value.vlan_id}})
                                                    </mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                        </div>
                                        <!-- TRUNK -->
                                        <div *ngIf="frmPort.value.mode == 'trunk'" class="flex row" style="align-items: flex-start; width: 100%;">
                                            <mat-form-field appearance="outline" class="mist-space">
                                                <mat-label>Port VLAN (Untagged/Native VLAN)</mat-label>
                                                <mat-select formControlName="port_network">
                                                    <mat-option value="default">default (1)</mat-option>
                                                    <mat-option *ngFor="let vlan of editingDeviceSettings.networks | mapToArray" [value]="vlan.key">
                                                        {{vlan.key}} ({{vlan.value.vlan_id}})
                                                    </mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                            <div class="flex row" style="flex-grow: 1;">
                                                <mat-checkbox formControlName="all_networks" appearance="outline" class="mist-space" style="margin-bottom: 1em;">Allow all VLANs</mat-checkbox>
                                                <mat-form-field appearance="outline" class="mist-space" style="flex-grow: 1;">
                                                    <mat-label>Allowed VLANs</mat-label>
                                                    <mat-select formControlName="networks" multiple [disabled]="frmPort.value.all_networks">
                                                    <mat-option value="default">default (1)</mat-option>
                                                    <mat-option *ngFor="let vlan of editingDeviceSettings.networks | mapToArray" [value]="vlan.key">
                                                        {{vlan.key}} ({{vlan.value.vlan_id}})
                                                        </mat-option>
                                                    </mat-select>
                                                </mat-form-field>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- LINK SETTINGS -->
                                <div class="flex row config-container bottom">
                                    <div class="config-subtitle">
                                        <mat-card-subtitle>LINK</mat-card-subtitle>
                                    </div>
                                    <div class="flex row">
                                        <div class="flex column mist-space" style="margin-bottom: 1em;">
                                            <mat-label>Auto-Negociation</mat-label>
                                            <mat-slide-toggle formControlName="autoneg">
                                                <span *ngIf="frmPort.value.autoneg == true">Enabled </span>
                                                <span *ngIf="frmPort.value.autoneg != true">Disabled </span>
                                            </mat-slide-toggle>
                                        </div>
                                        <div class="mist-space">
                                            <mat-form-field appearance="outline">
                                                <mat-label>SPEED</mat-label>
                                                <mat-select formControlName="speed" [disabled]="frmPort.value.autoneg">
                                                    <mat-option value="auto">Auto</mat-option>
                                                    <mat-option value="10m">10M</mat-option>
                                                    <mat-option value="100m">100M</mat-option>
                                                    <mat-option value="1g">1G</mat-option>
                                                    <mat-option value="2.5g">2.5G</mat-option>
                                                    <mat-option value="5g">5G</mat-option>
                                                </mat-select>
                                            </mat-form-field>

                                            <mat-form-field appearance="outline" style="margin-left: 1em;">
                                                <mat-label>DUPLEX</mat-label>
                                                <mat-select formControlName="duplex" [disabled]="frmPort.value.autoneg">
                                                    <mat-option value="auto">Auto</mat-option>
                                                    <mat-option value="full">Full</mat-option>
                                                    <mat-option value="half">Half</mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                        </div>
                                    </div>
                                </div>        
                            </form>
                    </mat-card-content>
                </mat-card>
                <div mat-dialog-actions style="justify-content: space-around;" class="flex row" *ngIf="selectedPorts.length >= 1">
                    <button mat-raised-button (click)="discardPorts()">Close</button>
                    <button mat-raised-button color="accent" (click)="savePorts()" [disabled]="frmPort.invalid">Update</button>
                </div>
            </div>
        </div>
    </div>
</mat-card>
</div>